<div class="game-over-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content">
    <div class="title">
      Elsodort a<br>
      <img src="/assets/images/cukorka-lavina.png" alt="fánk-folyam">
    </div>
    <div class="body">
      Összetört cukorkák: <strong>{{score}}</strong><br>
      <!--      <div class="uppercase" *ngIf="gameLeft > 1">-->
      <!--        Ma még {{gameLeft}} lehetőséged van fánkokat gyűjteni.-->
      <!--      </div>-->

      <!--      <div class="uppercase" *ngIf="gameLeft == 1">-->
      <!--        Ma már csak egy lehetőséged van fánkokat gyűjteni.-->
      <!--      </div>-->

      <!--      <div *ngIf="gameLeft == 0">-->
      <!--        Ma már háromszor szálltál szembe a fánk-folyammal. Nézz vissza holnap is, hogy Te lehess a legügyesebb farsangoló!-->
      <!--      </div>-->
      <div class="button-container" *ngIf="gameLeft > 0">
        <button class="fankfalo-button" (click)="restart()">
          Új játék
        </button>
      </div>
    </div>
  </div>
</div>
