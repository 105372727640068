import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { ForgottenPasswordPopupComponent } from './forgotten-password-popup/forgotten-password-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameOverPopupComponent } from './game-over-popup/game-over-popup.component';
import { NoMoreGameTodayComponent } from './no-more-game-today/no-more-game-today.component';
import { NeedLoginPopupComponent } from './need-login-popup/need-login-popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { RegisterPopupComponent } from './register-popup/register-popup.component';
import { EndGamePopupComponent } from './end-game-popup/end-game-popup.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ForgottenPasswordPopupComponent, GameOverPopupComponent, NoMoreGameTodayComponent, NeedLoginPopupComponent, ConfirmPopupComponent, RegisterPopupComponent, EndGamePopupComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ForgottenPasswordPopupComponent,
    GameOverPopupComponent,
    NoMoreGameTodayComponent,
    NeedLoginPopupComponent,
    EndGamePopupComponent
  ]
})
export class SharedModule {
}
