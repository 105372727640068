import { Injectable } from '@angular/core';
import { UserService } from '@eclick/core';
import { GameUser } from '../models/objects/game-user';

@Injectable({
  providedIn: 'root'
})
export class GameUserService extends UserService<GameUser> {

}
