import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, validateEmail } from '@eclick/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forgotten-password-popup',
  templateUrl: './forgotten-password-popup.component.html',
  styleUrls: ['./forgotten-password-popup.component.scss']
})
export class ForgottenPasswordPopupComponent implements OnInit {

  forgottenForm: FormGroup;
  submitted = false;
  emailSent = false;
  forgottenErrors = [];

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService,
              private dialogRef: MatDialogRef<ForgottenPasswordPopupComponent>) { }

  ngOnInit() {
    this.forgottenForm = this.formBuilder.group({
      email: [null, [Validators.required, validateEmail]]
    });
  }

  get email() {
    return this.forgottenForm.get('email');
  }

  shouldShowError(control: AbstractControl) {
    return control.invalid && ((control.dirty && control.touched) || this.submitted);
  }

  submit() {
    this.submitted = true;

    if (!this.forgottenForm.valid) {
      return;
    }

    this.authService.forgottenPassword(this.email.value).subscribe((response) => {
      this.emailSent = true;
    }, (response) => {
      this.forgottenErrors = response.error;
    });
  }

  close() {
    this.dialogRef.close();
  }

}
