<div class="header">
  <div class="logo">
    <img src="/assets/images/logo.png" alt="Fánk faló">
  </div>
  <div class="menu">
    <a routerLink="/">
      <div class="btn home-btn"></div>
    </a>
    <a routerLink="/jatek">
      <div class="btn game-btn"></div>
    </a>
<!--    <a routerLink="/nyeremenyek">-->
<!--      <div class="btn prizes-btn"></div>-->
<!--    </a>-->
  </div>
</div>
