import { Injectable } from '@angular/core';
import { ApiLanguageService, ApiResponse, ApiService, AuthenticationService, BaseAppInitService } from '@eclick/core';
import { GameUserService } from './game-user.service';
import { GetInitResponse } from '../models/responses/get-init.response';
import { DocumentsService } from './documents.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService extends BaseAppInitService<GetInitResponse> {

  public closed = false;

  constructor(protected api: ApiService, protected auth: AuthenticationService, protected apiLanguage: ApiLanguageService,
              private userService: GameUserService, private documentsService: DocumentsService) {
    super(api, auth, apiLanguage);
  }

  processData(response: ApiResponse<GetInitResponse>): any {
    const user = response.data?.user;

    if (user) {
      this.userService.setUserData(user).subscribe();
    }

    this.documentsService.setDocuments(response.data.documents);

    if (response.data.closed) {
      this.closed = true;
    }
  }
}
