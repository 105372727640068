<div class="game-over-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content">
    <div class="title">
      Sikeres regisztráció.
    </div>
    <div class="body">
      Kezdődhet a játék!
    </div>
  </div>
</div>
