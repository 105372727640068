<div class="forgotten-password-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content">
    <div class="title">
      Elfelejtetted a jelszavad?
    </div>
    <div class="body">
      Add meg a regisztráció során
      használt e-mail címed, és hamarosan küldünk
      neked egy új jelszót:
      <div class="forgotten-form">
        <form [formGroup]="forgottenForm" (ngSubmit)="submit()">
          <div class="form-row" [class.error]="shouldShowError(email)">
            <div class="input-row">
              <input type="email" name="email" formControlName="email">
            </div>
            <div class="error" *ngIf="shouldShowError(email)">
              <span *ngIf="email.errors.required">
                Az e-mail cím megadása kötelező!
              </span>
            </div>
          </div>
          <div *ngIf="emailSent">
            Az e-mail elküldésre került!
          </div>
          <div class="button-container">
            <button class="fankfalo-button">
              Elküldés
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
