<div class="game-over-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content" *ngIf="success">
    <div class="title">
      Siker!
    </div>
    <div class="body">
      Sikeresen megerősítettük a fiókod. Kérjük jelentkezz be!
    </div>
  </div>
  <div class="popup-content" *ngIf="!success">
    <div class="title">
      Hoppá!
    </div>
    <div class="body">
      Nem tudtuk megerősíteni a fiókodat!
    </div>
  </div>
</div>
