<footer>
  <div class="container">
    <div class="packshot">
      <img src="/assets/images/packshot.png" alt="Packshot" class="img-responsive">
    </div>
<!--    <div class="logo">-->
<!--      <img src="/assets/images/footer_logo.png" alt="Verbena logo" class="img-responsive">-->
<!--    </div>-->
  </div>
</footer>
