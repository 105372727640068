import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-end-game-popup',
  templateUrl: './end-game-popup.component.html',
  styleUrls: ['./end-game-popup.component.scss']
})
export class EndGamePopupComponent implements OnInit {

  @Output() newGame = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<EndGamePopupComponent>) {
  }

  ngOnInit() {
  }

  restart() {
    this.dialogRef.close(true);
  }


  close() {
    this.dialogRef.close();
  }

}
