import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-game-over-popup',
  templateUrl: './game-over-popup.component.html',
  styleUrls: ['./game-over-popup.component.scss']
})
export class GameOverPopupComponent implements OnInit {

  @Input() score = 0;
  @Input() limit = 0;
  @Input() gameLeft = 0;
  @Output() newGame = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<GameOverPopupComponent>) {
  }

  ngOnInit() {
  }

  restart() {
    this.dialogRef.close(true);
  }


  close() {
    this.dialogRef.close();
  }

}
