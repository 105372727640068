import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {
  ApiLanguageService,
  AppConfig,
  AuthenticationService,
  DefaultErrorHandler,
  EclickCoreModule,
  HeaderInterceptor,
  initApiFactory,
  initAuthFactory,
  LanguageService,
  NewErrorInterceptor,
  TokenInterceptor
} from '@eclick/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ForgottenPasswordPopupComponent } from './shared/forgotten-password-popup/forgotten-password-popup.component';
import { GameOverPopupComponent } from './shared/game-over-popup/game-over-popup.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NoMoreGameTodayComponent } from './shared/no-more-game-today/no-more-game-today.component';
import { NeedLoginPopupComponent } from './shared/need-login-popup/need-login-popup.component';
import { ConfirmPopupComponent } from './shared/confirm-popup/confirm-popup.component';
import { RegisterPopupComponent } from './shared/register-popup/register-popup.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EndGamePopupComponent } from './shared/end-game-popup/end-game-popup.component';
import { AppInitService } from './core/services/app-init.service';

const config: AppConfig = {
  apiUrl: 'https://admin.verbenakaracsony.promoc.io/api/',
  apiVersion: '1.0',
  supportedLanguages: ['hu', 'ro'],
  refreshTokenAvailable: true,
  dbPrefix: 'bombi_izagyu'
};

export function ErrorInterceptorFactory(errorHandler: DefaultErrorHandler) {
  return (new NewErrorInterceptor(config, errorHandler));
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    EclickCoreModule.forRootWithoutInterceptors(config),
    MatDialogModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    NgxPaginationModule
  ],
  entryComponents: [
    ForgottenPasswordPopupComponent,
    GameOverPopupComponent,
    NoMoreGameTodayComponent,
    NeedLoginPopupComponent,
    ConfirmPopupComponent,
    RegisterPopupComponent,
    EndGamePopupComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useFactory: ErrorInterceptorFactory, deps: [DefaultErrorHandler], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initAuthFactory, deps: [AuthenticationService], multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: initApiFactory, deps: [AppInitService, ApiLanguageService, LanguageService], multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
