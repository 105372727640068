<div class="game-over-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content">
    <div class="title">
      Hoppá!
    </div>
    <div class="body">
      Kérlek a játékhoz lépj be vagy regisztrálj!
    </div>
  </div>
</div>
