import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-more-game-today',
  templateUrl: './no-more-game-today.component.html',
  styleUrls: ['./no-more-game-today.component.scss']
})
export class NoMoreGameTodayComponent implements OnInit {

  @Input() score;

  constructor(private dialogRef: MatDialogRef<NoMoreGameTodayComponent>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
