import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent implements OnInit {


  constructor(private dialogRef: MatDialogRef<RegisterPopupComponent>) {
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }

}
