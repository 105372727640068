import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-need-login-popup',
  templateUrl: './need-login-popup.component.html',
  styleUrls: ['./need-login-popup.component.scss']
})
export class NeedLoginPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NeedLoginPopupComponent>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


}
