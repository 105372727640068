<div class="game-over-popup">
  <div class="close" (click)="close()">
    <img src="/assets/images/popup-close.png" alt="">
  </div>
  <div class="popup-content">
<!--    <div class="title">-->
<!--      Elsodort a<br>-->
<!--      <img src="/assets/images/fankfolyam.png" alt="fánk-folyam">-->
<!--    </div>-->
    <div class="body">
      A játék végetért!
    </div>
  </div>
</div>
